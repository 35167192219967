<template>
    <div class="flex items-center gap-7">
        <div class="font-bold hidden md:block">Our social media</div>

        <div class="flex gap-5">
            <ULink v-for="social of socialMedia" :to="social.link" target="_blank" class="w-11 h-11 inline-flex items-center justify-center text-blue-500 hover:text-blue-600">
                <UIIcon :name="social.icon"/>
                <span class="sr-only">{{social.alt}}</span>
            </ULink>
        </div>
    </div>
</template>

<script setup>
const {socialMedia} = await queryContent('data/settings').findOne();
</script>
